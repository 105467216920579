import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueNumberFormat from 'vue-number-format'


Vue.config.productionTip = false;
Vue.use(VueNumberFormat, {prefix: 'R$ ', decimal: ',', thousand: '.'})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
