<template>
    <div>
        <center class="red--text">
            <br>
            <h2>เข้าร่วมโครงการแล้วได้อะไร?</h2>
            <br>
            <h3>ส่วนลดเพิ่ม เพื่อกำไรที่งดงาม!!!</h3>
            <br>
            <h4>รับส่วนลดเพิ่มเติม <strong>2.25%</strong> จากยอดสั่งซื้อ ซึ่งแบ่งเป็น 2 เงื่อนไข</h4>
            <br>
        </center>  
        <v-container>
            <v-row dense>
                <v-col cols="12">
                    <v-card
                        color="#FFCDD2"
                        dark
                    >
                        <v-img src="@/assets/pgproduct.jpg"></v-img>
                        <v-card-title class="black--text" >
                            เงื่อนไขเพื่อรับรางวัล 
                        </v-card-title>
                        <v-card-subtitle class="black--text" >
                            ยอดการซื้อสินค้าตลอด 3 เดือน (ต.ค.-ธ.ค.65) เติบโต <strong class="red--text">12%</strong> จากปีที่แล้ว (ต.ค.-ธ.ค.64)                        
                        </v-card-subtitle>
                            <center class="red--text"><h3>รับส่วนลด  1% </h3></center> 
                        <v-card-actions>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-row dense>
                <v-col cols="12">
                    <v-card
                        color="#FFCDD2"
                        dark
                    >                       
                        <v-card-title class="black--text" >
                            รับส่วนลด ต่อที่ 2 
                        </v-card-title>
                        <v-card-subtitle class="black--text" >
                            ซื้อสินค้าแนะนำขายดี                      
                        </v-card-subtitle>
                            <center class="red--text"><h3>ตั้งแต่ 24 ตัว ขึ้นไป – รับส่วนลด 1.00%</h3></center> 
                            <center class="red--text"><h3>ตั้งแต่ 28 ตัว ขึ้นไป – รับส่วนลด 1.25%</h3></center>
                        <v-card-actions>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-container>

        </v-container>
        <v-container>
            <v-row dense>
                <v-col cols="12">
                    <v-card
                        color="#80D8FF"
                        dark
                    >
                        <v-img src="@/assets/display.jpg"></v-img>
                        <v-card-title class="black--text" >
                            ค่าเช่า ดิสเพลย์ 1,000 บาท/ไตรมาส 
                        </v-card-title>
                        <v-card-subtitle class="black--text" >
                            เงื่อนไขเพื่อเข้าร่วมรายการส่งเสริมการขาย                                                   
                        </v-card-subtitle >
                        <p class="black--text mx-5" >
                            1. ต้องมียอดการซื้อสินค้าขั้นต่ำต่อไตรมาส 50,000 บาท
                        </p>
                        <p class="black--text mx-5" >
                            2. มีดิสเพลย์ P&G วางอยู่บริเวณข้างๆ แคชเชียร์หรือโต๊ะที่เจ้าของร้านนั่งเก็บเงิน
                        </p>
                        <v-card-actions>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>         
        <v-container>
            <h5 class="blue--text">*ค่าเช่าดิสเพลย์สามารถแปลงเป็นมูลค่าของรางวัลได้ แต่จำเป็นต้องวางดิสเพลย์ในร้านค้าด้วย (ใช้รูปดิสเพลย์ในร้านค้าในการเคลม)</h5>
            <br>               
            <h5><strong class="red--text">หมายเหตุ : </strong>ของรางวัลมีจำนวนจำกัด และบริษัทอาจเปลี่ยนแปลงของรางวัลที่มีมูลค่าที่เที่ยบเท่ากัน / ขอสงวนสิทธิ์ในการเปลี่ยนโดยไม่แจ้งให้ทราบล่วงหน้า</h5>
            <br>         
        </v-container>
        <br>
    </div>
  </template>

<script>
    export default {
      data () {
        return {
            rewards: [
            {
              name: '5,000',
              reward: 'สินค้า (เช่น เครื่องใช้ในบ้าน/เครื่องใช้ไฟฟ้า/ทอง/สินค้าP&G) (มูลค่าเทียบเท่า 150บ.)',
              reward1: '',
            },
            {
              name: '7,000',
              reward: 'สินค้า (เช่น เครื่องใช้ในบ้าน/เครื่องใช้ไฟฟ้า/ทอง/สินค้าP&G) (มูลค่าเทียบเท่า 210บ.)',
              reward1: '',
            },
            {
              name: '9,000',
              reward: 'สินค้า (เช่น เครื่องใช้ในบ้าน/เครื่องใช้ไฟฟ้า/ทอง/สินค้าP&G) (มูลค่าเทียบเท่า 270บ.)',
              reward1: '',
            },            
            {
              name: 'มากกว่า 15,000',
              reward: 'สินค้า (เช่น เครื่องใช้ในบ้าน/เครื่องใช้ไฟฟ้า/ทอง/สินค้าP&G) (มูลค่าเทียบเท่า 450บ.) ',
              reward1: '+ ค่าเช่าดิสเพลย์ 1,000บ. ไตรมาส',
            },  
            {
              name: '*ยอดซื้อขั้นต่อไปเพิ่มขั้นละ 5,000บ. (เช่น 20,000บ, 25,000บ. เป็นต้น)',
              reward: 'สินค้า (เช่น เครื่องใช้ในบ้าน/เครื่องใช้ไฟฟ้า/ทอง/สินค้าP&G) (มูลค่าเทียบเท่ากับ 3% ของยอดซื้อขั้นนั้นๆ) ',
              reward1: '+ ค่าเช่าดิสเพลย์ 1,000บ. ไตรมาส',
            },            
          ],
        }
      },
    }
  </script>
  
 <style>
    @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;600&family=Prompt:wght@300&display=swap');

    html, body {
        font-family: 'Prompt', sans-serif;
    }

    #app {
        font-family: 'Prompt', sans-serif;
    }

    .p {
    font-size: 0.75em;
}

</style>