import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    store_code : "",
    store_class : "",
  },
  mutations: {
    setstore_code ( state, active_store_code ){
      state.store_code = active_store_code
    },
    setstore_class ( state, active_store_class ){
      state.store_class = active_store_class
    },
  },
  actions: {
    ac_setstore_code ( context , active_store_code ){
      context.commit( 'setstore_code' , active_store_code )
    },
    ac_setstore_class ( context , active_store_class ){
      context.commit( 'setstore_class' , active_store_class )
    },
  },
  modules: {},
  getters:{
    getstore_code ( state){
      return state.store_code
    },
    getstore_class ( state){
      return state.store_class
    },
  }
});
