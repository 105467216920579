<template>
    <div>
        <center class="red--text">
            <br>
            <h2>เข้าร่วมโครงการแล้วได้อะไร?</h2>
            <br>
            <h4>1. รับของรางวัลต่างๆได้ตามไตรมาส</h4>
            <h4>2. ได้รับการดูแลจากบริษัทฯอย่างใกล้ชิด เช่น</h4> 
            <h4>2.1 ตกแต่งร้านค้า เพื่อเพิ่มยอดขาย</h4>
            <h4>2.2 สื่อส่งเสริมการขาย (POSM) ฟรี</h4>
            <br>
        </center>  
        <v-container>
            <v-row dense>
                <v-col cols="12">
                    <v-card
                        color="#FFCDD2"
                        dark
                    >
                        <v-img src="@/assets/pgproduct.jpg"></v-img>
                        <v-card-title class="black--text" >
                            เงื่อนไขเพื่อรับรางวัล 
                        </v-card-title>
                        <v-card-subtitle class="black--text" >
                            มีคะแนน <strong class="red--text">DGP มากกว่า 70% </strong>หรือเทียบเท่ากับ <strong class="red--text">42 ตัว</strong> ขึ้นไป                        
                        </v-card-subtitle>

                        <v-card-actions>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                        ยอดจากการซื้อสินค้า 3 เดือน* (บาท)
                    </th>
                    <th class="text-left">
                        ของรางวัล 
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in rewards"
                    :key="item.name"
                    >
                    <td>{{ item.name }}</td>
                    <td>{{ item.reward }}<strong class="blue--text">{{item.reward1}}</strong></td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
            <br>
            <h5 class="red--text">**ยอดขายสะสม เดือน ต.ค.-ธ.ค. 2565</h5>
        </v-container>

        <v-container>

        </v-container>
        <v-container>
            <v-row dense>
                <v-col cols="12">
                    <v-card
                        color="#80D8FF"
                        dark
                    >
                        <v-img src="@/assets/display.jpg"></v-img>
                        <v-card-title class="black--text" >
                            ค่าเช่า ดิสเพลย์ 1,000 บาท/ไตรมาส 
                        </v-card-title>
                        <v-card-subtitle class="black--text" >
                            เงื่อนไขเพื่อเข้าร่วมรายการส่งเสริมการขาย                                                   
                        </v-card-subtitle >
                        <p class="black--text mx-5" >
                            1. ต้องมียอดการซื้อสินค้าขั้นต่ำต่อไตรมาส 20,000 บาท
                        </p>
                        <p class="black--text mx-5" >
                            2. มีดิสเพลย์ P&G วางอยู่บริเวณข้างๆ แคชเชียร์หรือโต๊ะที่เจ้าของร้านนั่งเก็บเงิน
                        </p>
                        <v-card-actions>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>         
        <v-container>
            <h5 class="blue--text">*ค่าเช่าดิสเพลย์สามารถแปลงเป็นมูลค่าของรางวัลได้ แต่จำเป็นต้องวางดิสเพลย์ในร้านค้าด้วย (ใช้รูปดิสเพลย์ในร้านค้าในการเคลม)</h5>
            <br>               
            <h5><strong class="red--text">หมายเหตุ : </strong>ของรางวัลมีจำนวนจำกัด และบริษัทอาจเปลี่ยนแปลงของรางวัลที่มีมูลค่าที่เที่ยบเท่ากัน / ขอสงวนสิทธิ์ในการเปลี่ยนโดยไม่แจ้งให้ทราบล่วงหน้า</h5>
            <br>         
        </v-container>
        <br>
    </div>
  </template>

<script>
    export default {
      data () {
        return {
            rewards: [         
            {
              name: 'มากกว่า 15,000',
              reward: 'สินค้า (เช่น เครื่องใช้ในบ้าน/เครื่องใช้ไฟฟ้า/ทอง/สินค้าP&G) (มูลค่าเทียบเท่า 450บ.) ',
              reward1: '+ ค่าเช่าดิสเพลย์ 1,000บ. ไตรมาส',
            },  
            {
              name: 'มากกว่า 20,000',
              reward: 'สินค้า (เช่น เครื่องใช้ในบ้าน/เครื่องใช้ไฟฟ้า/ทอง/สินค้าP&G) (มูลค่าเทียบเท่า 600บ.) ',
              reward1: '+ ค่าเช่าดิสเพลย์ 1,000บ. ไตรมาส',
            },
            {
              name: 'มากกว่า 25,000',
              reward: 'สินค้า (เช่น เครื่องใช้ในบ้าน/เครื่องใช้ไฟฟ้า/ทอง/สินค้าP&G) (มูลค่าเทียบเท่า 750บ.) ',
              reward1: '+ ค่าเช่าดิสเพลย์ 1,000บ. ไตรมาส',
            },                        
            {
              name: '*ยอดซื้อขั้นต่อไปเพิ่มขั้นละ 5,000บ. (เช่น 30,000บ, 35,000บ. เป็นต้น)',
              reward: 'สินค้า (เช่น เครื่องใช้ในบ้าน/เครื่องใช้ไฟฟ้า/ทอง/สินค้าP&G) (มูลค่าเทียบเท่ากับ 3% ของยอดซื้อขั้นนั้นๆ) ',
              reward1: '+ ค่าเช่าดิสเพลย์ 1,000บ. ไตรมาส',
            },            
          ],
        }
      },
    }
  </script>
  
 <style>
    @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;600&family=Prompt:wght@300&display=swap');

    html, body {
        font-family: 'Prompt', sans-serif;
    }

    #app {
        font-family: 'Prompt', sans-serif;
    }

    .p {
    font-size: 0.75em;
}

</style>