<template>
    <div>
        <center class="red--text">
            <br>
            <h3>แลกของรางวัล</h3>
            <br>
            <h3>จะเปิดให้บริการเร็วๆนี้</h3>
        </center>         
        <br>
    </div>
  </template>
  
  
<script>

import axios from "axios";

    export default {
      data () {
        return {
          invoices: [],
          total : 0,
        }
    },
    created() {
        
    },
    methods: {
        get_invoices(){

            axios.get(process.env.VUE_APP_WS+"getinvoices.php",{
                        params: {
                            scode : this.$store.getters.getstore_code ,
                        }
                    }).then(result=>{
                this.invoices  = JSON.parse(JSON.stringify(result.data))
                console.log( "check_registed data = " + result.data )


            })
            },

    },
    
    }
  </script>
  
 <style>
    @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;600&family=Prompt:wght@300&display=swap');

    html, body {
        font-family: 'Prompt', sans-serif;
    }

    #app {
        font-family: 'Prompt', sans-serif;
    }

    .p {
    font-size: 0.75em;
}

</style>