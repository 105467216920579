<template>
    <div>
        <center class="red--text">
            <br>
            <h3>ประวัติการซิื้อ</h3>
            <br>
        </center>  
        <v-container>
            <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                        วันที่
                    </th>
                    <th class="text-left">
                        เลขที่บิล 
                    </th>
                    <th class="text-left">
                        จำนวนเงิน 
                    </th>                    
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in invoices"
                    :key="item.invno"
                    >
                    <td>{{ item.invdate }}</td>
                    <td>{{ item.invno }}</td>
                    <td>{{ vueNumberFormat(item.amount , {prefix: '',suffix: '',decimal: '.',thousand: ','}) }}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
            <center><h4>ยอดขายรวม : {{vueNumberFormat(total, {prefix: '',suffix: '',decimal: '.',thousand: ','})}}</h4></center>
            <br>
            <h5 class="red--text">**ยอดขายสะสม เดือน ต.ค.-ธ.ค. 2565</h5>
        </v-container>
        <br>
    </div>
  </template>
  
  
<script>

import axios from "axios";

    export default {
      data () {
        return {
          invoices: [],
          total : 0,
        }
    },
    created() {
        this.get_invoices();
    },
    methods: {
        get_invoices(){

            axios.get(process.env.VUE_APP_WS+"getinvoices.php",{
                        params: {
                            scode : this.$store.getters.getstore_code ,
                        }
                    }).then(result=>{
                this.invoices  = JSON.parse(JSON.stringify(result.data))

                this.total = 0
                for (var i = 0; i < this.invoices.length; i++) {

                    console.log( this.invoices[i].amount , parseFloat(this.invoices[i].amount)  )
                    this.total = this.total  + (this.invoices[i].amount * 1.00 )
                }

            })
            },

    },
    
    }
  </script>
  
 <style>
    @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;600&family=Prompt:wght@300&display=swap');

    html, body {
        font-family: 'Prompt', sans-serif;
    }

    #app {
        font-family: 'Prompt', sans-serif;
    }

    .p {
    font-size: 0.75em;
}

</style>